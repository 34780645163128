const hostname = window.location.hostname;
const hostnameParts = hostname.split(".");
const NODE_ENV = process.env.NODE_ENV;
const origin =
  NODE_ENV === "development"
    ? "https://api.lenny.ai/"
    : window.location.origin + "/";
// const origin =
//   NODE_ENV === "development"
//     ? "http://52.3.145.163/"
//     : window.location.origin + "/";

// export const subdomain =
//   NODE_ENV === "development"
//     ? "5gpcqjh"
//     : hostnameParts.length === 3
//     ? hostnameParts[0]
//     : null;
export const subdomain =
  hostnameParts.length === 3 &&
  !["b2b-business", "business"].includes(hostnameParts[0])
    ? hostnameParts[0]
    : null;
//export const subdomain = null;
export const isSubdomain = !!subdomain;

const dev = {
  APP_NAME: "Application",
  API_ENDPOINT_URL: "https://api.lenny.ai/",
  //API_ENDPOINT_URL: "http://52.3.145.163/",
  // API_ENDPOINT_URL: "http://localhost:3000/",
  FRONTEND_DOMAIN: "localhost",
};

const prod = {
  APP_NAME: "Application",
  //API_ENDPOINT_URL: "http://52.3.145.163/",
  API_ENDPOINT_URL: "https://api.lenny.ai/",
  //API_ENDPOINT_URL: "http://localhost:3001/",
  FRONTEND_DOMAIN: "localhost",
};

const getEnv = () => {
  switch (NODE_ENV) {
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();
