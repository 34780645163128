import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Text from "antd/lib/typography/Text";
import ScholarshipFields3 from "./scolarship-fields3";
import ScholarshipCountDown from "./scholarship-countdown";
import {
  Title,
  DontWait,
  Header,
  Passage,
  Award,
  Description,
} from "./component/Title.js";
import moment from "moment";
import { Footer } from "./component/Footer.js";
import "./component/style.css";
const Theme3 = ({ scholarshipInfo, setScolarshipInfo }) => {
  const mobileWidth = window.innerWidth < 750;

  const renderRightScreen = () => {
    if (moment(scholarshipInfo?.recurrenceConfig?.start).isAfter()) {
      return (
        <Col
          style={{
            width: mobileWidth ? "100%" : "60%",
            textAlign: "center",
          }}
        >
          <div style={{ background: "#FFFFFF", padding: "20px" }}>
            <h1 className="">
              This scholarship hasn’t been launched yet. We’ll remind you when
              it's open for applications.
            </h1>
            <p className="">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </div>
        </Col>
      );
    } else if (moment(scholarshipInfo?.recurrenceConfig?.deadline).isBefore()) {
      return (
        <Col
          style={{
            width: mobileWidth ? "100%" : "60%",
            textAlign: "center",
          }}
        >
          <div style={{ background: "#FFFFFF", padding: "20px" }}>
            <h1 className="">This scholarship has been closed.</h1>
            <p className="">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </div>
        </Col>
      );
    } else {
      return (
        <>
          <Col
            style={{
              width: mobileWidth ? "100%" : "60%",
              textAlign: "center",
            }}
          >
            <div style={{ background: "#FFFFFF", padding: "20px" }}>
              <div
                style={{
                  position: "relative",
                  bottom: "50px",
                }}
              >
                <ScholarshipCountDown
                  scholarshipInfo={scholarshipInfo}
                  timeFinished={() => setScolarshipInfo(scholarshipInfo)}
                  style={{
                    color: "#000000",
                  }}
                  mainStyle={{
                    background: "#f3f3f4",
                  }}
                />
              </div>
              <DontWait
                text={"Apply now before it's too late"}
                subText={""}
                style={{
                  color: "#000000",
                }}
              />
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <ScholarshipFields3
                  scholarshipInfo={scholarshipInfo}
                  buttonStyle={{
                    textAlign: "left",
                    marginTop: "40px",
                  }}
                  termsStyle={{
                    color: "#000000",
                    float: "left",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <Passage
                style={{
                  color: "#000000",
                }}
                text={`The worthy is operated by ${
                  scholarshipInfo?.user?.firstName
                } and runs from ${moment(
                  scholarshipInfo?.recurrenceConfig?.start ?? new Date()
                ).format("MMMM DD, YYYY")} to ${moment(
                  scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
                ).format(
                  "MMMM DD, YYYY"
                )} . All students who are at least 16 years old and are legal resident of United States or India are eligible (additional may apply). Void where prohibited by law. No purchase necessary to enter or win.`}
              />
            </div>
          </Col>
        </>
      );
    }
  };

  return (
    <>
      <div className={`h-100`} style={{}}>
        <Header />
      </div>
      <div
        style={{ padding: "30px", backgroundImage: "url(/new/theme3a.png)" }}
      >
        <Row style={{ padding: "30px" }}>
          <Col
            style={{
              width: mobileWidth ? "100%" : "40%",
            }}
          >
            <Title
              scholarshipInfo={scholarshipInfo}
              style={{
                color: "#FFFFFF",
              }}
            />
            <div
              style={{
                marginTop: "25px",
              }}
            >
              <Award
                scholarshipInfo={scholarshipInfo}
                style={{
                  color: "#FFFFFF",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "320px",
              }}
            >
              <Description
                scholarshipInfo={scholarshipInfo}
                className={"blackColor"}
                style={{
                  color: "#000000",
                }}
              />
            </div>
          </Col>

          {renderRightScreen()}
        </Row>
      </div>
      <div style={{}}>
        <Footer />
      </div>
    </>
  );
};

export default Theme3;
